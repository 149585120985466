































































































































































































































































































































































































































.wrapper_collapse_for_header {
  padding: 10px;
}
.dialog_footers {
  display: flex;
  flex-direction: row-reverse;
}
::v-deep .el-radio__label {
  margin-right: 8px;
  justify-content: space-between;
}
::v-deep .cell {
  text-align: center;
  word-break: break-word;
}
